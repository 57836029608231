module.exports = [joinDefaults]

var formatCodeAsIndented = require('./util/format-code-as-indented')
var formatHeadingAsSetext = require('./util/format-heading-as-setext')

function joinDefaults(left, right, parent, context) {
  if (
    // Two lists with the same marker.
    (right.type === 'list' &&
      right.type === left.type &&
      Boolean(left.ordered) === Boolean(right.ordered)) ||
    // Indented code after list or another indented code.
    (right.type === 'code' &&
      formatCodeAsIndented(right, context) &&
      (left.type === 'list' ||
        (left.type === right.type && formatCodeAsIndented(left, context))))
  ) {
    return false
  }

  // Join children of a list or an item.
  // In which case, `parent` has a `spread` field.
  if (typeof parent.spread === 'boolean') {
    if (
      left.type === 'paragraph' &&
      // Two paragraphs.
      (left.type === right.type ||
        right.type === 'definition' ||
        // Paragraph followed by a setext heading.
        (right.type === 'heading' && formatHeadingAsSetext(right, context)))
    ) {
      return
    }

    return parent.spread ? 1 : 0
  }
}
