module.exports = html
html.peek = htmlPeek

function html(node) {
  return node.value || ''
}

function htmlPeek() {
  return '<'
}
