module.exports = strong
strong.peek = strongPeek

var checkStrong = require('../util/check-strong')
var phrasing = require('../util/container-phrasing')

// To do: there are cases where emphasis cannot “form” depending on the
// previous or next character of sequences.
// There’s no way around that though, except for injecting zero-width stuff.
// Do we need to safeguard against that?
function strong(node, _, context) {
  var marker = checkStrong(context)
  var exit = context.enter('strong')
  var value = phrasing(node, context, {before: marker, after: marker})
  exit()
  return marker + marker + value + marker + marker
}

function strongPeek(node, _, context) {
  return context.options.strong || '*'
}
