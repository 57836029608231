module.exports = paragraph

var phrasing = require('../util/container-phrasing')

function paragraph(node, _, context) {
  var exit = context.enter('paragraph')
  var subexit = context.enter('phrasing')
  var value = phrasing(node, context, {before: '\n', after: '\n'})
  subexit()
  exit()
  return value
}
